<template>
  <div class="app-account">
    <span v-if="!$store.getters.isLogin">
      <el-button type="primary" v-if="!isQihoo" @click="onQihooLogin">{{$t('app.header.account.login')}}</el-button>
      <el-button type="primary" v-if="isQihoo || route.query.intranet" @click="onIntranetLogin">{{$t('app.header.account.login')}}</el-button>
      <!-- <el-button type="primary" @click="onGithubLogin"
        >Github账号登录</el-button
      > -->
    </span>
    <el-dropdown
      v-else
      @command="onCommand"
      trigger="click"
      popper-class="accountPop advanced-operate"
      :popper-options="{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [10, 10],
            },
          },
        ],
      }"
    >
      <div class="app-account__content">
        <img class="app-account__avatar" :src="accountLogo" alt="" />
        <!-- <el-tooltip
          :content="$store.getters.userInfo.username"
          popper-class="plan-info-tips"
          :offset="20"
          placement="left"
        >
          <span class="app-account__name">{{
            $store.getters.userInfo.username
          }}</span>
        </el-tooltip> -->
        <span class="app-account__name">{{
          $store.getters.userInfo.username
        }}</span>
        <svg-icon class="app-account__arrow" name="xialasanjiao"></svg-icon>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="account">
            <svg-icon name="zhanghu"></svg-icon>
            <span class="dropdown-item__text">{{$t('app.header.account.center')}}</span>
          </el-dropdown-item>
          <!-- <el-dropdown-item command="account">
            <svg-icon name="zhanghu"></svg-icon>
            <span class="dropdown-item__text">{{$t('app.header.account.info')}}</span>
          </el-dropdown-item>
          <el-dropdown-item command="point">
            <svg-icon name="huiyuan1"></svg-icon>
            <span class="dropdown-item__text">{{$t('app.header.account.point')}}</span>
          </el-dropdown-item>
          <el-dropdown-item command="subscription">
            <svg-icon name="dingyue"></svg-icon>
            <span class="dropdown-item__text">{{$t('app.header.account.subscription')}}</span>
          </el-dropdown-item> -->
          <el-dropdown-item command="order">
            <svg-icon name="dingdan"></svg-icon>
            <span class="dropdown-item__text">{{$t('app.header.account.order')}}</span>
          </el-dropdown-item>
          <el-dropdown-item command="ownerteam">
            <svg-icon name="tuandui1"></svg-icon>
            <span class="dropdown-item__text">{{$t('app.header.account.team')}}</span>
          </el-dropdown-item>
          <el-dropdown-item command="images" v-if="!isProduct">
            <svg-icon name="zidingyijingxiang"></svg-icon>
            <span class="dropdown-item__text">{{$t('app.header.account.images')}}</span>
          </el-dropdown-item>
          <el-dropdown-item command="logout">
            <svg-icon name="tuichudenglu"></svg-icon>
            <span class="dropdown-item__text">{{$t('app.header.account.logout')}}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <!-- <el-dialog
      v-model="showLoginDialog"
      custom-class="login-dialog"
      width="280px"
      top="25vh"
      :before-close="handleCloseLogin"
      title="登录"
    >
      <div class="login-wrapper">
        <img :src="require('@/assets/img/login-logo.png')" alt="">
        <div class="login-btn__wrap">
          <div class="account-btn" @click="onQihooLogin">使用360账号登录</div>
        </div>
        <div class="github-login__wrap">
          <div class="squ-line__wrap">
            <div class="squ-line__text">或者</div>
            <div class="squ-line"></div>
          </div>
          <div class="github-btn" @click="onGithubLogin">
            <img :src="require('@/assets/img/github-login-logo.png')" />
            <span class="github-btn__text">使用Github登录</span>
          </div>
        </div>
      </div>
    </el-dialog> -->
    <!-- <el-dialog
      v-model="logoutVisible"
      title="提示"
      width="420px"
    >
      <div class="delete-team__content">
        <svg-icon name="info"></svg-icon>
        <span>你确认要退出系统么？</span>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="logoutVisible = false">取消</el-button>
          <el-button :loading="isLogout" type="primary" @click="logout">确认</el-button>
        </span>
      </template>
    </el-dialog> -->
  </div>
</template>

<script setup>
import { computed, onMounted, ref, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import emitter from '@/app/emitter.js'
import { loginIntranet } from 'app/auth'

const router = useRouter()
const route = useRoute()
const store = useStore()
const gProperties = getCurrentInstance().appContext.config.globalProperties
// const isNotChat = computed(() => {
//   return route.name !== 'chat'
// })
// const showLoginDialog = ref(false)
const logoutVisible = ref(false)
const isLogout = ref(false)
// const isLogining = ref(false)
const isProduct = window.location.host === 'ata.360.net'

const logout = async () => {
  isLogout.value = true
  await store.dispatch('user/logout')
  isLogout.value = false
  logoutVisible.value = false
  if (route.meta && route.meta.auth) {
    router.push({
      name: 'Home'
    })
  }
}

// const logoutHandle = () => {
//   logoutVisible.value = true
// }

const accountLogo = computed(() => {
  if (store.getters.userInfo.user_pic && store.getters.userInfo.user_pic.type && store.getters.userInfo.user_pic.base64) {
    return `data:image/${store.getters.userInfo.user_pic.type};base64,${store.getters.userInfo.user_pic.base64}`
  }
  return gProperties.$isDark() ? require('@/assets/img/avatar.png') : require('@/assets/img/avatar-light.png')
})

const isQihoo = window.location.host === 'ata.360zqaq.net'

onMounted(() => {
  if (route.name === 'Home' && route.query.redirect) {
    onQihooLogin()
  }
  emitter.on('showLogin', () => {
    if (isQihoo) {
      onIntranetLogin()
    } else {
      // router.replace({
      //   name: 'Home'
      // })
      if (route.meta && route.meta.auth) {
        router.push({
          name: 'Home',
          query: {
            redirect: route.fullPath
          }
        })
      } else {
        onQihooLogin()
      }
    }
  })
  emitter.on('logout', () => {
    // logoutHandle()
    logout()
  })
  // emitter.on('closeLoginPanel', () => {
  //   isLogining.value = false
  // })
})

// const loginAction = () => {
//   // console.log('执行登录弹窗')
//   showLoginDialog.value = true
// }
// const handleCloseLogin = () => {
//   // console.log(route.meta)
//   showLoginDialog.value = false
//   if (route.meta && route.meta.auth) {
//     router.push({
//       name: 'Home'
//     })
//   }
//   // return false
// }
// 内网域账户登录
const onIntranetLogin = () => {
  loginIntranet()
}

// 360账户登录
const onQihooLogin = () => {
  const activeElement = document.activeElement
  if (activeElement) {
    activeElement.blur()
  }
  // 处理偶现 登录窗口弹不出bug
  setTimeout(() => {
    window.QHPass && window.QHPass.signIn(function(param) {
      reloadPage()
    })
  }, 500)
  const textarea = document.querySelector('#chat-textarea') // bug 382494
  if (textarea) {
    textarea.blur()
  }
  // isLogining.value = true
}

const reloadPage = async () => {
  await store.dispatch('user/login')
  setTimeout(() => {
    // isLogining.value = false
  }, 1000)
  // emitter.emit('loadedUserInfo')
  if (route.name === 'search' || route.name === 'report' || route.name === 'Detection' || route.name === 'teamtask' || route.name === 'history' || route.name === 'statistics' || route.name === 'hunting' || route.name === 'hunting-static' || route.name === 'hunting-threat' || route.name === 'global') {
    emitter.emit('reloadResult')
  }
  if (route.query.redirect) {
    const path = route.query.redirect.split('?')
    const params = path[1] ? path[1].split('&') : []
    const obj = {}
    params.map(item => (obj[item.split('=')[0]] = item.split('=')[1]))
    router.push({ path: path[0], query: obj })
  }
}
// Github账户登录
// const onGithubLogin = () => {
//   loginGithub()
// }

const onCommand = (command) => {
  switch (command) {
    case 'logout':
      // logoutHandle()
      logout()
      break
    default:
      router.push({
        name: command
      })
  }
}
</script>
<style lang="scss" scoped>
.app-account {
  display: inline-block;
  margin-left: 16px;
  &__content {
    display: flex;
    align-items: center;
    cursor: pointer;
    &[aria-expanded="true"] {
      .app-account__arrow {
        color: #00ab7a;
      }
    }
  }
  &__avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
  &__name {
    margin-left: 10px;
    margin-right: 8px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 25px;
    color: var(--color-text-1);
  }
  &__arrow {
    color: var(--color-text-3);
    font-size: 7px;
  }
  // :deep(.login-dialog) {
  //   .el-dialog__footer {
  //     display: none;
  //   }
  //   .login-wrapper {
  //     padding-top: 20px;
  //     text-align: center;
  //     .login-btn__wrap {
  //       padding: 24px 0 20px;
  //       &>div {
  //         width: 160px;
  //         display: inline-block;
  //         height: 36px;
  //         line-height: 36px;
  //         color: #00ab7a;
  //         border: 1px solid #00ab7a;
  //         border-radius: 18px;
  //         cursor: pointer;
  //       }
  //     }
  //     .github-login__wrap {
  //       .squ-line__wrap {
  //         padding-bottom: 24px;
  //         display: inline-block;
  //         width: 160px;
  //         .squ-line {
  //           height: 1px;
  //           background: var(--color-text-3);
  //         }
  //         .squ-line__text {
  //           position: relative;
  //           top: 8px;
  //           color: var(--color-text-3);
  //           padding: 0 10px;
  //           display: inline-block;
  //           background: #202230;
  //         }
  //       }
  //       .github-btn {
  //         display: inline-block;
  //         width: 120px;
  //         line-height: 20px;
  //         font-size: 14px;
  //         color: var(--color-text-3);
  //         overflow: hidden;
  //         cursor: pointer;
  //         // &:hover {
  //         //   color: #00ab7a;
  //         // }
  //         &>img {
  //           float: left;
  //           margin-top: 3px;
  //         }
  //         &>span {
  //           float: left;
  //           margin-left: 5px;
  //         }
  //       }
  //     }
  //   }
  // }
}
.dropdown-item__text {
  padding-left: 8px;
}
</style>
<style lang="scss">
.delete-team__content {
  display: flex;
  min-height: 50px;
  svg {
    margin-right: 8px;
    font-size: 20px;
    color:#fa8c16;
  }
}
.advanced-operate {
  border: 0 !important;
  // box-shadow:2px 2px 10px black !important;
  box-shadow: var(--shadow);
  .el-popper__arrow {
    display: none;
  }
  .el-dropdown-menu__item {
    padding: 5px 20px;
    &>svg {
      font-size: 16px;
    }
  }
  .disabled {
    cursor: not-allowed;
    color: #525966;
  }
  .arrow-item {
    width: 100px;
    height: 22px;
    display:flex;
    justify-content:space-between;
    align-items:center;
  }
}
</style>
