<template>
  <div class="upgrade">
    <el-dialog :width="$isZh() ? '492px' : '800px'" v-model="visible" @close="close" :append-to-body="true" custom-class="upgradeDialog" :title="$t('upgrade.title')">
      <div class="info">
        <svg-icon name="info" color="#fa8c16"></svg-icon>
        <div v-if="customText" class="upgrade-content">{{customText}}</div>
        <div v-else-if="showPoint" class="upgrade-content">{{$t('upgrade.point')}}</div>
        <div v-else-if="showNormal" class="upgrade-content">{{$t('upgrade.normal')}}</div>
         <div v-else-if="showTeam" class="upgrade-content">{{$t('other.components.upgradeHunter')}}</div>
        <div v-else class="upgrade-content">{{$t('other.components.upgradeHunterText1')}}<br>{{$t('other.components.upgradeHunterText2')}}</div>
      </div>
      <div v-if="$slots.default" class="upgrade-slot">
        <slot></slot>
      </div>
      <div class="upgrade-footer__toPrice" @click="toPrice">{{$t('upgrade.toPrice')}}</div>
      <template #footer>
        <el-button @click="upgrade" ref="myButton" color="#00ab7a">{{$t('upgrade.action_upgrade')}}</el-button>
        <el-button v-if="showPoint" @click="toExchange" plain color="#00ab7a" style="background:transparent;">{{$t('upgrade.action_point')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import emitter from '@/app/emitter.js'
import { mapMutations, mapGetters } from 'vuex'
export default {
  props: {
    show: {
      type: Boolean,
      default: true
    },
    showPoint: {
      type: Boolean,
      default: true
    },
    showNormal: {
      type: Boolean,
      default: false
    },
    showTeam: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'A'
    },
    customText: {
      type: String,
      default: ''
    },
    upgradeType: {
      type: String,
      default: 'professional',
      validator: (value) => {
        return ['professional', 'enterprise'].indexOf(value) !== -1
      }
    }
  },
  data () {
    return {
      visible: this.show
    }
  },
  watch: {
    show (data) {
      this.visible = data
    }
  },
  // model: {
  //   prop: 'show',
  //   event: 'input'
  // },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    ...mapMutations([
      'SHOW_EXCHANGE_DIALOG'
    ]),
    close () {
      this.visible = false
      // 配合v-model
      this.$emit('update:show', false)
    },
    toPrice () {
      window.open('/price')
    },
    toExchange () {
      const group = this.userInfo.group
      if (!group) {
        emitter.emit('showLogin')
        return
      }
      this.close()
      this.SHOW_EXCHANGE_DIALOG(this.type)
    },
    upgrade () {
      const group = this.userInfo.group
      if (!group) {
        emitter.emit('showLogin')
        this.$refs.myButton.$el.blur()
        return
      }
      const query = {
        type: '',
        meal_type: this.upgradeType
      }
      if (group === 'free') {
        query.type = 'purchase'
      } else if (group === 'professional') {
        query.type = 'upgrade'
        query.meal_type = 'enterprise'
      }
      if (query.type !== 'upgrade') {
        query.price_type = this.price_type === 'year' ? 'one_year' : 'one_month'
      }
      const route = this.$router.resolve({ name: 'subscribe', query: query })
      window.open(route.href, '_blank')
    }
  }
}
</script>
<style lang="scss">
.upgradeDialog {
  .el-dialog__header {
    font-size: 16px;
    color: var(--color-text-1);
  }
  .el-dialog__body {
    padding-bottom: 20px;
    .info {
      display: flex;
      color: var(--color-text-2);
      align-items: center;
      svg {
        font-size: 20px;
      }
    }
  }
  .el-dialog__footer {
    button:nth-child(1) {
      color: #fff;
    }
    button:nth-child(2) {
      &:hover {
        color: var(--color-primary);
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.upgrade  {
  &-title  {
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: #F5F7FF;
    text-align: left;
    line-height: 24px;
    position: relative;
    padding-left: 36px;
    & img  {
      position: absolute;
      left: 0;
    }
  }
  &-content  {
    font-size: 14px;
    color: var( --color-unkown-text);
    text-align: justify;
    line-height: 22px;
    padding-left: 8px;
  }
  &-slot  {
    padding-top: 20px;
  }
  &-footer  {
    &__toPrice  {
      margin-top:16px;
      font-size: 14px;
      color: #00ab7a;
      display: inline-block;
      cursor: pointer;
      &:hover  {
        filter: brightness(1.2);
      }
    }
    &__btn  {
      height: 32px;
      width: 108px;
      background: #537EF3;
      font-size: 14px;
      color: var(--color-text-1);
      text-align: center;
      line-height: 32px;
      display: inline-block;
      cursor: pointer;
      transform: translateY(-8px);
      &:hover  {
        filter: brightness(1.2);
      }
    }
    &__btn2  {
      color: #668CF4;
      margin-left: 16px;
      background: transparent;
      border: 1px solid #537EF3;
    }
  }
}
</style>
