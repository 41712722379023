export default {
  report: {
    title: '任务报告：{0} - 360沙箱云',
    overview: {
      platform: '目标平台',
      file_type: '文件类型',
      task_type: '任务类型',
      specific_count: {
        threaten_quota: '威胁指标',
        host: '网络主机',
        virus_name: '病毒名',
        dropped: '释放文件',
        process: '进程数',
        signatures_quota: '规则指标'
      },
      chart: {
        non_task: '暂无可查看任务',
        dynamic_score: '动态分值',
        static_score: '静态分值',
        is_threaten: {
          malice: '恶意',
          suspicious: '可疑',
          secure: '未发现威胁'
        }
      },
      operate: {
        share: {
          name: '共享',
          weibo: '分享到 微博',
          wechat: '分享到 微信',
          twitter: '分享到 Twitter',
          facebook: '分享到 Facebook',
          title: '聚焦样本检测报告：{0} #360沙箱云#'
        },
        handle: {
          name: '操作',
          range: {
            title: '可见范围',
            public: '公开',
            private: '私有'
          },
          team: '分享至团队',
          non_team: '暂无团队',
          delete: '删除任务'
        },
        redetect: '重新检测',
        download: {
          name: '下载',
          sample: '下载样本',
          pcap: '下载 PCAP',
          'decrypted-pcap': '解密 PCAP',
          'original-pcap': '原始 PCAP',
          report: '下载报告',
          pdf: 'PDF 下载',
          permissions_limited: '权限不足',
          confirm: '确认下载',
          warm: '您了解您下载的内容可能包含病毒、恶意程序、代码且可能具有破坏性。您同意不会将任何此类恶意内容用于恶意目的。',
          warm1: '为确保您的设备安全，我们对样本进行压缩加密，解压密码：',
          undone: '未完成报告无法下载',
          process: '文件下载中，请耐心等待！',
          ing: '报告下载中',
          done: '报告下载完成'
        }
      },
      detail: {
        environment: {
          label: '检测环境',
          static: '静态检测',
          suffix: '{0}位'
        },
        shutdown_on: '提交时间',
        continued: '持续时间',
        duration: '动态检测',
        formatter: '{0} 秒',
        config: {
          label: '查看',
          title: '任务配置详情',
          task: {
            title: '任务配置',
            second: '秒',
            on: '开启',
            off: '关闭',
            auto_setting: '自动设置',
            file_in_zip: '指定文件名：',
            md5_in_zip: '指定 MD5：',
            type_in_zip: '指定文件类型：',
            bit: '位',
            basic: {
              label: '基本检测配置',
              children: [
                {
                  label: '快速检测',
                  default: '快速检测',
                  map: '@:(detection.config.basic.strategy)'
                },
                {
                  label: '操作系统',
                  default: 'Windows 7 Professional Service Pack 1 32位 (build: 7601)'
                },
                {
                  label: '系统语言',
                  default: '中文简体'
                }
              ]
            },
            dynamic: {
              label: '动态检测配置',
              children: [
                {
                  label: '指定浏览器打开',
                  default: 'Internet Explorer'
                },
                {
                  label: '文件类型',
                  default: '自动识别'
                },
                {
                  label: '命令行'
                },
                {
                  label: '动态库参数',
                  default: '-',
                  function: '函数名',
                  params: '参数'
                },
                {
                  label: '解压密码'
                },
                {
                  label: '指定压缩文件中的子文件'
                },
                {
                  label: '文档密码'
                },
                {
                  label: '指定应用打开文档'
                },
                {
                  label: '邮件文件的处理方式'
                },
                {
                  label: '自定义文件名',
                  default: '-'
                },
                {
                  label: '自定义扩展名',
                  default: '-'
                },
                {
                  label: '指定目录下执行进程',
                  default: '自动设置'
                }
              ]
            },
            strategy: {
              label: '检测策略配置',
              children: [
                {
                  label: '检测时长',
                  default: '120秒'
                },
                {
                  label: '模拟点击',
                  default: '-'
                },
                {
                  label: '人类操作模拟'
                },
                {
                  label: '自动点击'
                },
                {
                  label: '自动滚动'
                },
                {
                  label: '剪贴板填充'
                },
                {
                  label: '鼠标移动'
                },
                {
                  label: '自动安装'
                },
                {
                  label: '快速动态检测'
                },
                {
                  label: '模拟重启'
                },
                {
                  label: '主动重启'
                },
                {
                  label: '暴力破解密码'
                },
                {
                  label: '时区'
                },
                {
                  label: '所在地区'
                },
                {
                  label: '系统日期与时间'
                },
                {
                  label: '环境变量'
                },
                {
                  label: '模拟杀软进程'
                },
                {
                  label: '睡眠跳过'
                }
              ]
            },
            network: {
              label: '网络检测配置',
              children: [
                {
                  label: '连接互联网',
                  default: '关闭'
                },
                {
                  label: '解密 HTTPS 流量',
                  default: '关闭'
                }
              ]
            }
          },
          app: '应用软件',
          install: '安装更新',
          dependent: '依赖组件'
        }
      },
      process: '进程列表'
    },
    report_tabs: {
      analyse: '分析概览',
      task: '任务摘要',
      static: '静态分析',
      behavior: '行为分析',
      network: '网络分析',
      dropped: '释放文件',
      memdump: '释放内存',
      indicator: '威胁指标',
      graph: '关联分析',
      full_report: '查看完整报告',
      process: '进程',
      event: '事件'
    },
    analyse: {
      aiengine: '智能引擎',
      static: {
        title: '静态判定',
        engine_judgment: {
          title: '引擎判定',
          risk: {
            malice: '恶意',
            suspicious: '可疑',
            not_detected: '未检出',
            non_risk: '无风险'
          },
          key: '引擎名称',
          result: '检出结果',
          engine_key_map: {
            qvm_info: '人工智能引擎',
            aqvm_info: '人工智能引擎（移动版）',
            bd_info: '增强型查杀引擎Ⅰ',
            sign_info: '签名扫描引擎',
            avira_info: '增强型查杀引擎Ⅱ',
            ave_info: '启发式查杀引擎',
            kpav_info: '鲲鹏查杀引擎',
            avm_info: '启发式查杀引擎（移动版）',
            qex_info: '脚本查杀引擎',
            cloud_info: '增强型查杀引擎Ⅲ'
          }
        }
      },
      indicator: {
        marks: '{0}个事件'
      },
      mitre_att: {
        title_label: 'MITRE ATT&CK™ 技术点检测',
        title_0: '存在',
        title_1: '个 ATT&CK 技术点',
        hide_missed: '隐藏未命中的技术点',
        dialog: {
          title: 'MITRE ATT&CK™ 技术点',
          name: '名称',
          tactics: '战术',
          required_permissions: '所需权限',
          explain: '说明',
          source: '来源',
          target: '指标'
        }
      },
      behavior: {
        title: '行为判定',
        result: 'MITRE ATT&CK™ 技术检测结果',
        show_all: '显示全部技术点',
        return_value: '返回值',
        repeat: '重复{0}次'
      },
      screenshoot: '运行截图',
      network: '网络概要',
      summary: {
        title: '行为总览',
        process_id: '进程 ID',
        process_name: '进程名',
        search: '输入内容以搜索事件'
      }
    },
    static: {
      info: {
        url_title: 'URL 信息',
        file_title: '文件信息',
        file_label: {
          name: '文件名称',
          size: '文件大小',
          qex_type: '文件类型',
          type: '文件格式'
        }
      },
      content: {
        title: '文件内容',
        tab_data: '数据',
        tab_string: '字符串'
      },
      apk: {
        title: 'APK 权限',
        label: '权限'
      },
      email: {
        title: '邮件信息',
        from: '发送人',
        to: '接收人',
        cc: '抄送',
        subject: '主题',
        date: '日期',
        attachment: '附件',
        attachment_name: '文件名'
      },
      lnk: {
        title: '快捷方式信息',
        basepath: '目标',
        relapath: '起始位置',
        cmdline: '命令行',
        icon: '图标',
        description: '描述'
      },
      email_body: '邮件正文',
      pe_info: {
        title: 'PE信息',
        pe_timestamp: '编译时间',
        pe_imphash: '导入表哈希',
        pdb_path: '符号路径',
        pe_shells: 'PE 壳'
      },
      pe_sections: {
        title: '区段',
        name: '名称',
        virtual_address: '虚拟地址',
        virtual_size: '虚拟大小',
        size_of_data: '原始数据大小',
        entropy: '熵'
      },
      pe_resources: {
        title: '资源',
        name: '名称',
        offset: '偏移',
        size: '大小',
        language: '语言',
        sublanguage: '子语言',
        filetype: '文件类型'
      },
      pe_imports: '导入表',
      pe_exports: {
        title: '导出表',
        ordinal: '序号',
        address: '地址',
        name: '名称'
      },
      version: '版本信息',
      signature: {
        title: '数字签名',
        common_name: '签名者姓名',
        serial_number: '序列号',
        sha1: 'SHA1 指纹',
        md5: 'MD5 指纹'
      },
      zip_list: {
        title: '压缩文件列表',
        filename: '文件名',
        compress_size: '压缩后大小',
        file_size: '原始大小',
        date_time: '修改日期'
      },
      office_summary: {
        title: 'OFFICE 信息',
        _title: '标题',
        subject: '主题',
        keywords: '标记',
        category: '类别',
        comments: '备注',
        author: '作者',
        last_modified_by: '最后⼀次保存者',
        revision: '修订号',
        version: '版本号',
        created: '创建时间',
        modified: '最后一次保存时间',
        content_status: '内容状态',
        language: '语言'
      },
      vba_info: {
        title: 'VBA 信息',
        filename: '文件名',
        stream: '流'
      },
      eps_info: {
        title: 'EPS 信息',
        eps_name: '文件名'
      },
      ole_info: {
        title: 'OLE 信息',
        name: '文件名'
      },
      swf_info: {
        title: 'SWF 信息',
        name: '文件名'
      },
      elf_info: {
        title: 'ELF 信息',
        data: '数据',
        ei_version: '版本',
        type: '文件类型',
        machine: '硬件架构',
        version: '文件版本',
        number_of_program_headers: '程序头数',
        number_of_section_headers: '区段头数',
        entry_point_address: '入口点地址'
      },
      elf_sections: {
        title: 'ELF 区段',
        name: '名称',
        addr: '地址',
        size: '大小',
        type: '类型'
      },
      elf_relocations: {
        title: 'ELF 重定位表',
        name: '名称',
        offset: '偏移',
        value: '值',
        info: '信息',
        type: '类型'
      },
      elf_program: {
        title: 'ELF 程序信息',
        flags: '标记',
        addr: '地址',
        size: '大小',
        type: '类型'
      },
      elf_symbol: {
        title: 'ELF 符号表',
        ndx_name: '索引名称',
        value: '值',
        bind: '绑定',
        type: '类型'
      }
    },
    behavior: {
      relation: {
        title: '行为关系图',
        start: {
          label: '开始分析',
          info: '行为信息',
          sample: '样本',
          time: '启动时间',
          score: '分值'
        },
        tooltip: {
          dropped: '释放文件',
          domains: '访问域名',
          hosts: '访问主机',
          http: '访问HTTP',
          https: '访问HTTPS',
          process_id: '进程 ID',
          process_name: '进程名',
          process_type: '进程类型'
        },
        tools: {
          simple: '切换简单模式',
          full: '切换完整模式',
          amplification: '放大',
          shrink: '缩小',
          reset: '刷新'
        },
        tree_icon: {
          start: '开始',
          zip: '压缩包',
          system: '系统工具',
          script: '脚本进程',
          resources: '文件资源管理器',
          registry: '注册表',
          pointBoard: '画图',
          other: '其他进程',
          note: '记事本',
          dynamic: '动态库',
          droped: '释放的文件',
          drop: '释放文件',
          domains: '访问域名',
          hosts: '访问主机',
          https: '访问 HTTPS',
          http: '访问 HTTP',
          calculator: '计算器',
          command: '命令行',
          startProgress: '启动进程',
          dotted: '文件或网络行为'
        }
      },
      tree: '进程树',
      summary: {
        pid: '进程 ID',
        ppid: '父进程 ID',
        username: '用户名',
        integrity_sid: '完整性等级',
        first_seen: '启动时间',
        process_type: '进程类型',
        process_path: '文件路径',
        user_sid: '用户 SID',
        command_line: '命令行',
        parent_path: '父进程路径',
        parent_name: '父进程名',
        parent_cmdline: '父进程命令行'
      },
      signature: '行为判定',
      events: {
        title: '行为事件',
        detail: '详细',
        simple: '简单'
      }
    },
    status: {
      pending: '等待调度',
      scanning: '静态扫描中',
      scanning_completed: '扫描完成',
      policy_running: '策略运行中',
      dynamic_pending: '等待动态调度',
      static_dispatch_waiting: '静态扫描中',
      dynamic_dispatch_waiting: '动态已调度',
      running: '动态运行中',
      processing: '动态分析中',
      completed: '报告生成中',
      reported: '已报告',
      default: '失败'
    },
    network: {
      traffic: {
        alerts: {
          src_ip: '源 IP 地址',
          dst_ip: '目的 IP 地址',
          category: '类型',
          severity: '严重程度',
          info: '流量信息',
          protocol: '传输协议',
          src_port: '源端口',
          detect: '流量检测',
          signature: '特征',
          timestamp: '时间戳',
          payload: '数据内容'
        }
      },
      hosts: {
        ip: 'IP 地址',
        country: '国家或地区',
        city: '城市',
        isp: 'ISP',
        info: '主机信息',
        domain: '域名'
      },
      dns: {
        area_name: '域名名称',
        analyse: '解析结果',
        info: '解析信息',
        response: '解析记录'
      },
      http: {
        info: '请求信息',
        content: '数据内容'
      },
      tcp: {
        src: '源 IP',
        src1: '源 IP 地址',
        sport: '源端口',
        dst: '目的 IP',
        dst1: '目的 IP 地址',
        dport: '目的端口',
        info: '连接信息',
        protocol: '协议',
        protocol1: '传输协议',
        domain: '域名',
        process: '连接进程'
      },
      ftp: {
        command: '命令'
      },
      smtp: {
        info: '访问信息',
        dst: '目的 IP 地址',
        raw: '数据信息'
      },
      icmp: {
        src: '源 IP 地址',
        type: 'ICMP 类型',
        dst: '目的 IP 地址',
        data: '数据',
        info: '访问信息'
      },
      irc: {
        command: '命令',
        type: '类型',
        params: '参数',
        info: '访问信息'
      }
    },
    release: {
      name: '文件名',
      filesize: '文件大小',
      type: '文件类型',
      engineJudge: '引擎判定',
      filename: '文件名称',
      filepath: '文件路径',
      format: '文件格式',
      info: '文件信息',
      content: '文件内容'
    },
    memory: {
      filesize: '文件大小',
      filetype: '文件类型',
      engineJudge: '静态引擎判定',
      info: '文件信息',
      content: '文件内容',
      context: '上下文信息',
      type: '文件类型',
      format: '文件格式',
      context_pid: '上下文进程',
      target_pid: '目标进程',
      address: '内存地址',
      size: '内存大小',
      protection: '内存属性'
    },
    indicator: {
      ioc: '情报指标',
      ioc_type: '情报类型',
      threaten_type: '威胁类型',
      confidence_quota: '可信指数',
      risk_quota: '严重程度',
      domain: '域名情报',
      ip: 'IP 情报',
      file: '文件信誉',
      threat: '威胁指标',
      status: '情报状态',
      not_expired: '有效',
      expired: '过期',
      organization: '威胁组织',
      family: '威胁家族',
      tag: '威胁标签'
    },
    graph: {
      file: '文件',
      domain: '域名',
      taskid: '任务 ID:',
      task_count: '任务数:'
    },
    report_status: {
      private: '该任务为私有数据，您无访问权限',
      error: '系统错误',
      null: '任务不存在',
      denied: '无权限查看此任务报告的内容',
      failed: '任务检测失败'
    }
  },
  taskstatus: {
    tasklist: '本次提交任务',
    history: '在{mine}中查看记录',
    mine: '我的任务',
    pending: '静态调度',
    scanning: '静态扫描',
    dynamic: '动态调度',
    running: '动态运行',
    analyse: '动态分析',
    reported: '报告生成'
  }
}
