import { createStore } from 'vuex'
import getters from './getters'
import user from './modules/user'
import team from './modules/team'
import language from './modules/language'
import app from './modules/app'
import theme from './modules/theme'
import routes from './modules/routes'
import publiclist from './modules/public'
import history from './modules/history'
import teamtask from './modules/teamtask'
import report from './modules/report'
import search from './modules/search'
import { message } from '@/hooks/useUI'
import { t } from 'app/i18n'

import {
  ElMessage
} from 'element-plus'

export default createStore({
  getters,
  state: {
    username: null,
    joinDate: null,
    login: false,
    reportOpen: false,
    reportTab: '',
    messagebox: null,
    userinfo: {
      group: '',
      gid: 0
    },
    frequencyMessageBox: null,
    feedbackMessageBox: null,
    exchangeDialogVisible: false,
    exchangeDialogType: 'A',
    rechangeDialogVisible: false,
    scoreInfo: null
  },
  mutations: {
    LOGIN(state, payload) {
      state.login = true
      state.username = payload.nickname || payload.username
      state.joinDate = payload.date_joined || '-'
      state.userinfo = payload
    },
    LOGOUT(state) {
      state.token = null
      state.username = null
      state.login = false
      state.userinfo = {
        group: '',
        gid: 0
      }
      localStorage.removeItem('token')
    },
    SETTOKEN(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    REPORT_OPEN(state) {
      state.reportOpen = true
    },
    REPORT_CLOSE(state) {
      state.reportOpen = false
    },
    CHANGE_REPORT_TAB(state, payload) {
      state.reportTab = payload.tab
    },
    CLOSEMESSAGE(state) {
      if (state.messagebox) {
        state.messagebox.close()
      }
    },
    SHOWMESSAGE(state, data) {
      message(data.message, data.type)
    },
    SHOWFREQUENCY(state, str) {
      if (state.frequencyMessageBox) {
        state.frequencyMessageBox.close()
      }
      state.frequencyMessageBox = ElMessage({
        dangerouslyUseHTMLString: true,
        duration: 0,
        showClose: true,
        type: 'error',
        customClass: 'frequencyMsgBox',
        message: `<span>${str}<a href="/price" target="_blank" class="frequencyLink" style="color: #00ab7a;margin-left: 8px;">${t('action.show_detail')}</a></span>`
      })
    },
    SHOWFEEDBACK(state, str) {
      if (state.feedbackMessageBox) {
        state.feedbackMessageBox.close()
      }
      state.feedbackMessageBox = ElMessage({
        dangerouslyUseHTMLString: true,
        duration: 0,
        showClose: true,
        type: 'error',
        customClass: 'frequencyMsgBox',
        message: `<span>${str}<a href="/help/consulting" target="_blank" class="frequencyLink" style="color: #00ab7a;margin-left: 8px;">${'联系管理员'}</a></span>`
      })
    },
    SHOW_EXCHANGE_DIALOG(state, data) {
      state.exchangeDialogVisible = true
      state.exchangeDialogType = data
    },
    CLOSE_EXCHANGE_DIALOG(state) {
      state.exchangeDialogVisible = false
    },
    SHOW_RECHANGE_DIALOG(state) {
      state.rechangeDialogVisible = true
    },
    CLOSE_RECHANGE_DIALOG(state) {
      state.rechangeDialogVisible = false
    }
  },
  modules: {
    user,
    language,
    app,
    theme,
    routes,
    team,
    publiclist,
    history,
    teamtask,
    report,
    search
  }
})
