<template>
  <div class="app-notice">
    <el-popover
      placement="bottom-start"
      :width="560"
      popper-class="notice-popover"
      :show-arrow="false"
      trigger="click"
      :visible="showNoticeList"
    >
      <template #reference>
        <div style="height: 100%;" @click="handleShow">
          <svg-icon class="app-notice__icon" name="xiaoxi"></svg-icon>
          <div class="app-notice__symbol" v-if="hasData"></div>
        </div>
      </template>
      <div class="notice-popover-header">
        <span class="notice-popover-header__title">{{$t('app.header.notice.mine')}}</span>
        <span class="close" @click="handleClose">
          <el-icon><Close /></el-icon>
        </span>
        <span class="notice-popover-header__setting" @click="handleNotice('setting')">
          {{$t('app.header.notice.setting')}}
        </span>
        <span class="notice-popover-header__message" @click="handleNotice('message')">
          {{$t('app.header.notice.all')}}
        </span>
      </div>
      <div class="popover-content__wrap">
        <div v-if="hasData">
          <div class="notice-list__item" v-for="(item, index) in tableList" :key="index">
            <template v-if="item.message_type === 0">
              <div class="notice-item__header">
                <span class="notice-item__type">{{ item.msg_type }}</span>
                <span class="notice-item__time">{{ item.send_time }}</span>
              </div>
              <div class="notice-item__content">
                <span class="notice-item__text">{{ item.message_content }}</span>
                <span class="notice-item__action" @click="showDetail(item)">{{$t('app.header.notice.detail')}}</span>
              </div>
            </template>
            <template v-if="item.message_type === 3">
              <div class="notice-item__header">
                <span class="notice-item__type">{{ item.msg_type }}</span>
                <span class="notice-item__time">{{ item.send_time }}</span>
              </div>
              <div class="notice-item__content">
                <span class="notice-item__text system_content" :class="{ active: item.isShow }">{{ item.message_content }}</span>
                <span class="notice-item__open" @click="handleOpenContent(item)">
                  <el-icon v-show="!item.isShow"><ArrowDown /></el-icon>
                  <el-icon v-show="item.isShow"><ArrowUp /></el-icon>
                </span>
                <span class="notice-item__action" @click="showDetail(item)">{{$t('app.header.notice.detail')}}</span>
              </div>
            </template>
          </div>
        </div>
        <no-data v-else :data="$t('app.header.notice.nodata')" />
      </div>
    </el-popover>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import { message } from '@/hooks/useUI'
import { useState } from '@/hooks/useVuex'
import emitter from '@/app/emitter.js'
import { ref, onMounted, computed } from 'vue'
import { Close, ArrowDown, ArrowUp } from '@element-plus/icons-vue'
import { getMessageList, putMessage } from '@/services/notice.js'
import dayjs from 'dayjs'
import { t } from 'app/i18n'

const tableList = ref([])
const { userInfo } = useState('user', ['userInfo'])
const router = useRouter()
const route = useRoute()
const showNoticeList = ref(false)

onMounted(() => {
  initEvent()
  getData()
})

const initEvent = () => {
  emitter.on('reloadNotice', () => {
    getData()
  })
}
const getData = () => {
  getMessageList({
    is_read: 0,
    page: 1,
    size: 50
  }).then(res => {
    tableList.value = res.messages.data.map(item => {
      return {
        ...item,
        msg_type: item.message_type === 0 ? t('app.header.notice.team') : t('app.header.notice.system'),
        send_time: dayjs(item.send_time).format('YYYY-MM-DD HH:mm:ss')
      }
    })
  })
}

const hasData = computed(() => {
  return tableList.value && tableList.value.length
})

const handleOpenContent = (item) => {
  item.isShow = !item.isShow
}
const handleNotice = (name) => {
  showNoticeList.value = false
  router.push({
    name
  })
}

const handleClose = () => {
  showNoticeList.value = false
}

const handleShow = () => {
  showNoticeList.value = true
}

const showDetail = (item) => {
  putMessage({
    message_ids: [item.id]
  }).then((res) => {
    if ('code' in res) {
      message(res.message, 'error')
    } else {
      getData()
    }
  })
  showNoticeList.value = false
  // 团队消息，执行跳转
  if (item.message_type === 0) {
    console.log(item.ext_data.team_id)
    if (item.ext_data.team_owner_id === userInfo.value.user_id) {
      // 发送方，反馈消息跳转到对应团队页面
      if (route.name === 'ownerteam') {
        emitter.emit('changeTeam', item.ext_data.team_id)
      } else {
        router.push({
          name: 'ownerteam',
          params: {
            team_id: item.ext_data.team_id
          }
        })
      }
    } else {
      // 接收方，接受跳转到对应团队页面
      if (item.ext_data.invited_state === 2) {
        if (route.name === 'ownerteam') {
          emitter.emit('changeTeam', item.ext_data.team_id)
        } else {
          router.push({
            name: 'ownerteam',
            params: {
              team_id: item.ext_data.team_id
            }
          })
        }
      } else {
        router.push({
          name: 'inviteteam'
        })
      }
    }
  }
  // 系统消息，执行跳转
  if (item.message_type === 3) {
    router.push({
      name: 'err-hash'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.app-notice {
  position: relative;
  width: 25px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 45px;
  &__icon {
    font-size: 20px;
    color: var(--color-text-1);
    cursor: pointer;
  }
  &__symbol {
    position: absolute;
    right: 0;
    top: 7px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #ff5048;
  }
}

.notice-popover {
  background: #202230;
  box-shadow: var(--shadow);
  border-radius: 2px;
  max-height: 690px;
  border-width: 0;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  .notice-popover-header {
    border-bottom: 1px solid var(--color-border-2);
    padding: 12px 24px;
    line-height: 24px;
    overflow: hidden;
    &__title {
      float: left;
      font-size: 16px;
      color: var(--color-text-1);
    }
    &__message {
      font-size: 14px;
      float: right;
      cursor: pointer;
      color: var(--color-text-3);
      &:hover {
        color: var(--color-primary);
      }
    }
    &__setting {
      padding: 0 24px;
      float: right;
      cursor: pointer;
      color: var(--color-text-3);
      &:hover {
        color: var(--color-primary);
      }
    }
    .close {
      // margin-right: auto;
      padding-top: 3px;
      float: right;
      font-size: 18px;
      cursor: pointer;
      color: var(--color-text-3);
      &:hover {
        color: var(--color-primary);
      }
    }
  }
  .popover-content__wrap {
    padding: 12px 24px;
    max-height: 500px;
    overflow-y: auto;
    :deep(.nodata-wrap) {
      padding: 25px 0;
    }
    :deep(.no-data) {
      width: 56px !important;
      height: 56px !important;
    }
    .notice-list__item {
      line-height: 27px;
      padding: 5px;
      .notice-item__header {
        overflow: hidden;
        .notice-item__type {
          float: left;
          color: var(--color-text-3);
        }
        .notice-item__time {
          float: right;
          color: var(--color-text-3);
        }
      }
      .notice-item__content {
        overflow: hidden;
        .notice-item__text {
          float: left;
          max-width: 410px;
          line-height: 20px;
          color: var(--color-text-1);
          &.system_content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.active {
              white-space: normal;
            }
          }
        }
        .notice-item__open {
          cursor: pointer;
          float: left;
          font-size: 16px;
          // padding-top: 2px;
          // line-height: 20px;
          margin-left: 5px;
        }
        .notice-item__action {
          float: right;
          cursor: pointer;
          color: var(--color-primary-7);
        }
      }
    }
  }
}
</style>
