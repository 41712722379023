import {
  ElMessage,
  ElLoading,
  ElNotification,
  ElMessageBox
} from 'element-plus'

/**
 * 全局弹出消息
 * @param {*} msg
 * @param {*} type success, message, warning, error
 */
export function message(msg, type = 'success') {
  if (type === 'error') {
    return ElMessage.error(msg)
  } else {
    return ElMessage({
      message: msg,
      type,
      showClose: false,
      duration: 1000
    })
  }
}

/**
 * 全局Loading
 */
export function loading() {
  return ElLoading.service({
    lock: true,
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

/**
 * 全局通知
 */
export function notification(message, title, option = {}) {
  return ElNotification({
    title,
    message,
    ...option
  })
}

/**
 * 全局alert
 */
export function alert(message, title, option = {}) {
  return ElMessageBox.alert(message, title, option)
}

/**
 * 全局confirm
 */
export function confirm(message, title, option = {}) {
  return ElMessageBox.confirm(message, title, option)
}

/**
 * 全局toast
 */
export function toast(message) {}
