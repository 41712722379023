<template>
  <div v-if="!isSearching" @click="search"><svg-icon class="app-search__icon" name="sousuo"></svg-icon></div>
  <div class="app-search" :style="{width: isSearching ? '220px' : '0px'}">
    <input ref="inputRef" v-model.trim="input" @keyup.enter="handleSearch" :placeholder="$t('app.header.search')" class="app-search__input" type="text" />
    <svg-icon @click="handleSearch" :class="{active: input.length > 0}" class="app-search__icon" name="sousuo"></svg-icon>
    <el-icon v-if="isSearching" color="#7c7f8d" :size="22" @click="close"><Close /></el-icon>
  </div>
</template>

<script setup>
import { Close } from '@element-plus/icons-vue'
import { ref, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { t } from 'app/i18n'

const isSearching = ref(false)
const input = ref('')
const inputRef = ref(null)
const store = useStore()
const router = useRouter()
const route = useRoute()
defineProps({
  focusFn: {
    type: Function,
    default: () => {}
  }
})
const emit = defineEmits(['focusFn'])
function search () {
  isSearching.value = true
  emit('focusFn', true)
  // nextTick(() => inputRef.value.focus())
  setTimeout(() => {
    inputRef.value.focus()
  }, 600)
}
function close () {
  isSearching.value = false
  emit('focusFn', false)
  input.value = ''
}

function handleSearch() {
  if (!input.value) return
  // const reg = /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+.+$/ // eslint-disable-line
  // const reg2 = /(^[a-fA-F0-9]{32}$)|(^[a-fA-F0-9]{40}$)|(^[a-fA-F0-9]{64}$)/
  if (input.value.length > 1990) {
    store.commit('app/showMessage', {
      type: 'error',
      message: t('other.components.errString'),
      duration: 1500
    })
    return
  }
  if (route.query.querystr !== input.value) {
    // todo
    router.push({ name: 'search', query: { querystr: input.value } })
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.app-search {
  height: 32px;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: width .5s;
  position: absolute;
  left: 85px;
  &__input {
    width: 150px;
    margin-left: 12px;
    flex: 1;
    outline: none;
    border: none;
    background-color: transparent;
    color: var(--color-text-1);
    ::placeholder {
      color: var(--color-text-4);
    }
  }
  .active {
    color: var(--color-primary);
  }
  .el-icon {
    font-size: 20px;
    cursor: pointer;
  }
  &__icon {
    width: 28px !important;
    margin: 0 8px;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: #00ab7a;
    }
  }
}
</style>
