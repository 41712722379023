export default {
  app: {
    title: '360威胁情报平台',
    switchTheme: '主题切换',
    switchLang: '语言切换',
    menu: {
      submit: '提交分析',
      task: '任务中心',
      hunting: '狩猎中心',
      help: '帮助中心',
      ti: '安全大脑',
      upgrade: '升级服务',
      trends: '威胁态势',
      track: '威胁追踪',
      datasub: '高级检索'
    },
    lang: {
      zh: '简体中文',
      en: 'English'
    },
    home: {
      upload: '点击或拖动至此处上传文件以进行检测',
      inputPlaceholder: '输入 MD5、SHA1、SHA256 或 URL 以查找报告',
      searchHistory: '在历史报告中搜索',
      newTask: '以新任务提交检测',
      hour: '24小时',
      day: '7天',
      fullTask: '全站任务',
      taskCount: '任务数量',
      trendingTags: '热门标签',
      threatmap: '威胁地图',
      trendingfamilies: '热门家族',
      trendinggroup: '热门组织',
      malicious: '恶意',
      suspicious: '可疑',
      clean: '未发现威胁',
      urls: 'URL',
      files: '文件',
      quickstart1: '快速入门',
      quickstart2: '帮助中心、快速入门',
      quickstart3: '查看沙箱云帮助教程',
      feature1: '产品介绍',
      feature2: '产品特性、一触即达',
      feature3: '查看产品介绍',
      service1: '升级服务',
      service2: '专业服务、为你专享',
      service3: '了解沙箱云增值服务体系',
      gpt: '沙箱云聊天助手',
      gptPlacholder: '请问需要什么帮助呢...',
      questionnaire: '问卷调查',
      contact: '联系我们',
      backtop: '回到顶部',
      nodata: '暂无数据'
    },
    intro: {
      title: '360沙箱云',
      logo: '专业的高级威胁分析平台，洞悉恶意样本每一行为',
      desc: '360沙箱云具备领先的漏洞利用检测技术和全方位的样本行为分析能力，构筑漏洞攻防知识与高级威胁检测能力的闭环，让威胁无所遁行。',
      try: '立即体验',
      whitePapper: '下载白皮书',
      colorPage: '下载彩页',
      customer: '以精准的洞察，帮助企业发现威胁。与他们一起，共建威胁检测生态。',
      order: '（排序不分先后，仅为部分客户）',
      productAdvantage: {
        title1: '全方位的行为检测分析',
        title2: '高精准的漏洞利用检测',
        title3: '极速智能的反病毒引擎',
        title4: '丰富的威胁族群和规则',
        title5: '多样的自定义环境配置',
        title6: '海量样本数据处理能力',
        content1: '用户态的监控技术往往容易被恶意软件绕过而失效。360沙箱云覆盖用户态和内核态的进程、文件、注册表、网络等关键行为检测，通过全方位的行为检测分析，快速剖析恶意样本行为。',
        content2: '360沙箱云聚焦已知漏洞识别和漏洞利用行为发现，监控样本执行过程中的用户态利用、内核态利用、进程崩溃、系统蓝屏及已知漏洞触发等行为。迄今已捕获 5 次在野零日漏洞攻击，共获得 15 次 CVE 致谢。助力用户发现已知漏洞攻击，捕获零日漏洞、高级和未知威胁。',
        content3: '360沙箱云依托五大反病毒查杀引擎，基于深度学习技术，毫秒级检测识别木马、病毒、勒索软件等恶意代码，为样本分析提供精准静态鉴定结果。',
        content4: '360沙箱云具有近 2000 条行为判定规则，包括近 200 种威胁家族、130+种威胁组织及近 100 种行为特征标签，为用户提供样本的完整行为的判定依据，理解攻击手段和动机，即时采取安全响应措施。',
        content5: '某些恶意软件只有在特定环境下才会触发恶意活动。360 沙箱云基于长期的样本运营和威胁追踪实践，提供多样的可配置环境选项，支持用户对环境和检测策略进行设定，进一步触发目标对象的恶意行为，或对比不同环境下的行为差异。',
        content6: '360沙箱云每日处理全球数百万高质量威胁样本，累积检测样本超 20 亿，黑样本超 2 亿，情报数据超 3 亿。海量安全数据的处理和运营，满足用户大规模样本检测需求，以及黑样本检索需求，助力用户追踪溯源、威胁狩猎。'
      },
      typicalScenarisList: {
        title1: '精准风险判定',
        title2: '实时威胁检测',
        title3: '威胁情报生产',
        content1: '精准的样本风险判定能力，为高级威胁、反病毒分析团队等用户提供精准的判定结果和行为依据，助力分析团队筛选、发现和追踪最新活跃 的APT攻击样本和常规恶意样本，洞悉最新趋势，减轻分析成本负担。企业内或自由从业的安全研究人员，对于关注的恶意样本，可在沙箱中获得丰富的检测报告，包括判定结果和完整执行过程。',
        content2: '实时的威胁检测能力，通过 API 接入企业客户的安全响应基础设施，能够实时检测企业内网接触的未知样本，并对样本执行过程进行全方位监控和洞察以产生威胁告警，使威胁无所遁形。上下游的自动化防御设施，可以实时提交和获取样本的检测过程和结果，极短时间对检出的威胁告警进行应对，做到发现和响应的有效结合。',
        content3: '完整的执行过程和网络行为还原能力，生成和输出丰富且准确的情报指标，为威胁情报管理平台（TIP）、安全信息事件管理平台（SIEM）等企业客户的安全设施提供源源不断的动力。基于精准的威胁判定能力和完整的检测报告输出，沙箱云能还原样本执行的全部过程，提取高价值和高准确度的威胁指标数据，有效降低误报情况，提高威胁情报和安全响应设施的检测能力。'
      },
      selectedReports: '精选的任务报告。基于强大的沙箱检测核心技术，为您提供精准完整的检测报告。',
      servicePrice: {
        free: {
          title: '免费版',
          desc: '免费版为用户提供在稳定的 Windows 7 32 位操作系统环境下对目标对象的检测服务，并提供精准的静态和行为判定结果。此外沙箱云为免费版用户开放公开任务数据库供用户检索和查询。',
          characteristic: '主要服务特性如下：',
          item1: '提交文件和 URL 分析任务',
          item2: '检测文件参数自定义',
          item3: '交互式分析',
          item4: 'Mitre ATT&CK 映射矩阵',
          item5: '行为关系图',
          item6: '恶意软件家族攻击行为检测',
          btn: '免费试用'
        },
        professional: {
          title: '专业版',
          desc: '在免费版服务的基础上，专业版为用户提供更广泛的检测环境和检测功能，用户可以在 Windows 7 64 位操作系统上运行目标对象，且支持更多的应用环境和参数配置，对样本进行更深入的分析。',
          characteristic: '在免费版基础上新增的主要服务特性如下：',
          item1: 'Windows 7 64 位操作系统',
          item2: '全量应用环境集',
          item3: 'HTTPS 流量解密',
          item4: '用指定的浏览器分析 URL',
          item5: '分析报告数据私有',
          item6: '下载样本和 PCAP 包',
          item7: '勒索病毒、逃避检测、普通漏洞利用等行为检测',
          btn: '查看详情'
        },
        professionalPlus: {
          title: '专业增强版',
          desc: '专业增强版为经验丰富的安全分析人员提供了更多捕获高级威胁和攻击的功能。用户可以在覆盖了大部分主流的 Windows 操作系统环境中最大化发挥自身经验优势，享受威胁分析过程中参数配置的灵活性和更强大的检测功能，且借助漏洞利用行为的发现能力，提升发现零日漏洞的机会。',
          characteristic: '在专业版基础上新增的主要服务特性如下：',
          item1: 'Windows 8.1 /10 32/64 位操作系统',
          item2: '区域参数、环境变量和 DLL 参数自定义',
          item3: '操作系统重启',
          item4: '已知漏洞和关键漏洞利用行为检测',
          item5: 'APT 攻击行为检测',
          item6: '最高的任务分析优先级',
          item7: '最高的分析任务配额',
          btn: '查看详情'
        },
        enterprise: {
          title: '企业版',
          desc: '企业版在前述各版本的基础上，实现完全定制化。结合企业客户实际业务需求，对沙箱检测能力和功能进行全方位的定制化，为企业客户提供最大化的威胁检测服务，赋能客户构筑漏洞攻防知识与高级威胁检测能力的闭环，让威胁无所遁行。',
          characteristic: '提供专属服务特性如下：',
          item1: '高级搜索、哈希提交等专属服务',
          item2: '定制化的威胁检测平台环境',
          item3: '定制化的自定义环境配置选项',
          item4: '专属的知识和专家服务体系',
          item5: '完整的威胁检测指标和判定能力',
          item6: '定制化的专属任务分析优先级',
          item7: '定制化的专属分析任务配额',
          btn: '查看详情'
        }
      }
    },
    header: {
      search: '输入内容以搜索报告',
      notice: {
        mine: '我的消息',
        setting: '消息设置',
        all: '查看全部',
        detail: '查看详情',
        nodata: '暂无消息',
        team: '团队消息',
        system: '系统消息'
      },
      account: {
        login: '登录',
        info: '账户信息',
        point: '我的积分',
        subscription: '我的订阅',
        order: '订单记录',
        team: '团队合作',
        logout: '退出登录',
        center: '账户中心',
        images: '自定义镜像'
      }
    },
    sidebar: {
      public: '公开任务',
      history: '我的任务',
      teamtask: '团队任务',
      advanced: '高级搜索',
      statistics: '我的统计',
      global: '全站任务',
      setting: {
        label: '任务中心设置',
        page: '页面',
        show: '显示',
        default: '默认'
      },
      expand: '展开',
      fold: '收起'
    },
    footer: {
      brain: 'Powered by 360核心安全大脑',
      company: '三六零数字安全科技集团有限公司',
      icp: '京ICP备20015286号-8',
      license: '工商营业执照',
      net_security: '京公网安备11010502041971号'
    }
  }
}
