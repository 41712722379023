<template>
  <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('copy')" placement="top">
    <span :class="['copy-icon', 'copy', name]" @click="handleClick" :style="{marginLeft: `${left}px`, marginRight: `${right}px`}">
      <slot name="text">
        <svg-icon class="item-icon copy" name="fuzhi1"></svg-icon>
      </slot>
    </span>
  </el-tooltip>
</template>

<script setup>
import ClipboardJS from 'clipboard'
import { useStore } from 'vuex'
import { defineProps, onMounted, onUnmounted, ref } from 'vue'
import { t } from 'app/i18n'
import { copy } from '@/hooks/useCopy'
const store = useStore()
const props = defineProps({
  copyText: {
    type: String
  },
  left: {
    type: Number,
    default: 4
  },
  right: {
    type: Number,
    default: 4
  },
  name: {
    type: String
  },
  grey: {
    type: Boolean,
    default: false
  }
})

const clipboard = ref(null)
const textStr = ref('')

const handleClick = () => {
  // textStr.value = props.copyText
  copy(props.copyText)
}

onMounted(() => {
  clipboard.value = new ClipboardJS(`.${props.name}`, {
    text: () => {
      return textStr.value
    }
  })
  clipboard.value.on('success', (e) => {
    store.commit('app/showMessage', { message: t('copied'), duration: 800 })
  })
  clipboard.value.on('error', function (e) {
  })
})
onUnmounted(() => {
  clipboard.value.destroy()
})
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
  .copy-icon {
    cursor: pointer;
    color: var(--color-primary);
    &:hover {
      filter: brightness(1.2);
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
</style>
