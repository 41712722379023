export default {
  app: {
    title: '360 ATA Cloud',
    copyright: 'All rights reserved',
    center: '360 Internet Security Center',
    switchTheme: 'Theme switch',
    switchLang: 'Language switch',
    menu: {
      submit: 'SUBMIT',
      task: 'REPORTS',
      hunting: 'HUNTING',
      help: 'HELP',
      upgrade: 'PRICING',
      trends: 'TRENDS',
      ti: 'TI',
      track: 'TRACKER',
      datasub: 'SEARCH'
    },
    lang: {
      zh: '简体中文',
      en: 'English'
    },
    home: {
      upload: 'Click or drag files here to upload to detect',
      inputPlaceholder: 'Input MD5, SHA1, SHA256 or URL to find reports',
      searchHistory: 'Search in the global task',
      newTask: 'Submit as a new task',
      hour: '24 hours',
      day: '7 days',
      fullTask: 'Full Tasks',
      taskCount: 'Task count',
      trendingTags: 'Trending tags',
      threatmap: 'Threat map',
      trendingfamilies: 'Trending families',
      trendinggroup: 'Trending groups',
      malicious: 'Malicious',
      suspicious: 'Suspicious',
      clean: 'Clean',
      urls: 'URLs',
      files: 'Files',
      quickstart1: 'Quick start',
      quickstart2: 'Help Hub, quick start',
      quickstart3: 'View the Help tutorial',
      feature1: 'Product intro',
      feature2: 'One key to the product feature',
      feature3: 'View the product introduction',
      service1: 'Services',
      service2: 'Professional services for you',
      service3: 'View pricing',
      gpt: 'Chat assistant',
      questionnaire: 'Questionnaire',
      contact: 'Contact us',
      backtop: 'Back to top',
      nodata: 'No data'
    },
    intro: {
      title: '360 ATA Cloud',
      logo: 'Professional advanced threat analysis platform, insight into each behavior of malwares',
      desc: '360 ATA Cloud has leading vulnerability detection technology and in-depth and comprehensive sample behavior analysis capabilities, to build a closed loop of vulnerability attack and defense knowledge and advanced threat detection capabilities, let threats all-encompassing.',
      try: 'Try it now',
      whitePapper: 'Get white paper',
      colorPage: 'Get colorpage',
      customer: 'Help businesses identify threats with precise insights. Work with them to build a threat detection ecosystem.',
      order: '(Sorted in no particular order, only some customers are displayed)',
      productAdvantage: {
        title1: 'Behavior Detection',
        title2: 'Accurate Exploit Detection',
        title3: 'Antivirus Engines',
        title4: 'Families and Rules',
        title5: 'Custom Environment',
        title6: 'Massive Sample',
        content1: 'User mode monitoring technology is often easily bypassed by malicious software and becomes ineffective. 360 ATA Cloud covers user and kernel behavior, achieving comprehensive detection of key behaviors such as processes, files, registries, networks, system activities, advanced attack and defense, and RPC. Through in-depth behavior detection analysis, malicious sample behavior can be quickly analyzed.',
        content2: '360 ATA Cloud focuses on identifying known vulnerabilities and discovering exploitative behaviors, monitoring user mode exploitation, kernel mode exploitation, process crashes, system blue screens, and known vulnerability triggers during sample execution. So far, 5 in the wild zero day vulnerability attacks have been captured, and a total of 15 CVE acknowledgments have been received. Assist users in discovering known vulnerability attacks, capturing zero day vulnerabilities, advanced, and unknown threats.',
        content3: '360 ATA Cloud relies on five major antivirus killing engines and deep learning technology to detect and identify malicious codes such as trojans, viruses, and ransomware at a millisecond level, providing accurate static identification results for sample analysis.',
        content4: '360 ATA Cloud has nearly 2000 behavior determination rules, including nearly 200 threat families, over 130 threat organizations, and nearly 100 behavior feature labels, providing users with a complete basis for determining the behavior of samples, understanding attack methods and motivations, and taking immediate security response measures.',
        content5: 'Some malware will trigger malicious activities only in a specific environment. Based on the long-term sample operation and threat tracking practice, 360 ATA Cloud provides a variety of configurable environment options, supports users to set the environment and detection strategies, further trigger the malicious behavior of the target object, or compare the behavior differences in different environments.',
        content6: '360 ATA Cloud processes millions of high-quality threat samples around the world every day, with cumulative detection samples exceeding 2 billion, black samples exceeding 200 million and intelligence data exceeding 300 million. The processing and operation of massive security data can meet the needs of users for large-scale sample detection and black sample retrieval, and help users track the source and hunt threats.'
      },
      typicalScenarisList: {
        title1: 'Accurate Risk Assessment',
        title2: 'Real Time Threat Detection',
        title3: 'Threat Intelligence Production',
        content1: 'Accurate sample risk assessment capability provides accurate assessment results and behavioral basis for advanced threat and antivirus analysis teams and other users, assisting the analysis team in screening, discovering, and tracking the latest active APT attack samples and conventional malicious samples, gaining insight into the latest trends, and reducing the burden of analysis costs. Security researchers within the enterprise or freely employed can obtain rich detection reports, including judgment results and complete execution processes, in the sandbox for malicious samples of interest.',
        content2: `Real time threat detection capability, connected to the security response infrastructure of enterprise customers through APIs, can detect unknown samples that come into contact with the enterprise intranet in real time, and monitor and insight the sample execution process comprehensively to generate threat alerts, making threats invisible. The automated defense facilities upstream and downstream can submit and obtain the detection process and results of samples in real-time, respond to detected threat alarms in a very short time, and achieve an effective combination of discovery and response.`,
        content3: 'The complete execution process and network behavior restoration ability generate and output rich and accurate intelligence indicators, providing continuous power for the security facilities of enterprise customers such as the Threat Intelligence Management Platform (TIP) and Security Information Event Management Platform (SIEM). Based on precise threat determination capabilities and complete detection report output, 360 ATA Cloud can restore the entire process of sample execution, extract high-value and high-precision threat indicator data, effectively reduce false positives, and improve the detection capabilities of threat intelligence and security response facilities.'
      },
      selectedReports: 'Selection of task reports. Based on the powerful sandbox detection core technology, to provide you with accurate and complete inspection reports.',
      servicePrice: {
        free: {
          title: 'Free',
          desc: 'The Free provides users with detection services for target objects in a stable Windows 7 32 bit OS environment, as well as accurate static and behavioral determination results. In addition, Sandbox Cloud is open to users for retrieval and query by the free plan of the public task database.',
          characteristic: 'Key service features:',
          item1: 'Submiting file and URL detection tasks',
          item2: 'Detected file arguments customization',
          item3: 'Interactive analysis',
          item4: 'Mitre ATT&CK matrix mapping',
          item5: 'Behavioral diagram',
          item6: 'Malware family attack behavior detection',
          btn: 'Try free'
        },
        professional: {
          title: 'Pro',
          desc: 'Based on the free plan of the service, the Pro plan provides users with a wider range of detection environments and detection capabilities, allowing users to run target objects on Windows 7 64-bit OS and support more application environments and parameter configurations for more in-depth analysis of samples.',
          characteristic: 'Key service features added to Free plan:',
          item1: 'Windows 7 64 bit OS',
          item2: 'Full application environment sets',
          item3: 'HTTPS traffic decryption',
          item4: 'Detecting URLs with specified browser',
          item5: 'Private analysis reports data',
          item6: 'Export samples and PCAP',
          item7: 'Ransomware, evasion detection, common exploit',
          btn: 'Buy now'
        },
        professionalPlus: {
          title: 'Pro Plus',
          desc: 'The Pro Plus plan provides experienced security analysts with more ability to capture advanced threats and attacks. Users can maximize their experience in environments that cover most mainstream Windows operating systems, enjoy the flexibility of parameter configuration and more powerful detection capabilities during threat analysis, and enhance the chances of discovering 0-day with the discovery of exploit behavior.',
          characteristic: 'Key service features added to the PRO plan:',
          item1: 'Windows 8.1 /10 32/64 bit OS',
          item2: 'Local, variable, and DLL arguments customization',
          item3: 'Rebooting the OS',
          item4: 'Known CVEs and critical exploit',
          item5: 'APT behavior detection',
          item6: 'The highest task analysis priority',
          item7: 'The highest analysis task quota',
          btn: 'Buy now'
        },
        enterprise: {
          title: 'Enterprise',
          desc: 'Enterprise plan is completely customized on the basis of the previous plans. Combined with the actual business needs of enterprise customers, the sandbox detection capabilities and functions are customized in an all-round way, providing enterprise customers with maximum threat detection services, enabling customers to build a closed loop of vulnerability attack and defense knowledge and advanced threat detection capabilities, so that threats have no escape.',
          characteristic: 'Provide exclusive service features:',
          item1: 'Exclusive services such as Advanced Search & Hash Submission',
          item2: 'Customized threat detection platform environment',
          item3: 'Customized custom environment configuration options',
          item4: 'Exclusive knowledge and expert service system',
          item5: 'Complete threat detection indicators and detection ability',
          item6: 'Customized task specific analysis priorities',
          item7: 'Customized and exclusive analysis task quota',
          btn: 'Buy now'
        }
      }
    },
    header: {
      search: 'Enter to search for reports',
      notice: {
        mine: 'my message',
        setting: 'Message settings',
        all: 'view all',
        detail: 'View details',
        nodata: 'No news yet',
        team: 'Team news',
        system: 'system information'
      },
      account: {
        login: 'Log in',
        info: 'Profile',
        point: 'My points',
        subscription: 'My plan',
        order: 'Order record',
        team: 'Teamwork',
        logout: 'Sign out',
        center: 'Account center',
        images: 'Custom images'
      }
    },
    sidebar: {
      public: 'Public task',
      history: 'History',
      teamtask: 'Team tasks',
      advanced: 'Advanced Search',
      statistics: 'My statistics',
      setting: {
        label: 'Settings',
        page: 'page',
        show: 'show',
        default: 'default'
      },
      expand: 'Expand',
      fold: 'close'
    },
    footer: {
      brain: 'Powered by 360核心安全大脑',
      company: '三六零数字安全科技集团有限公司',
      icp: '京ICP备20015286号-8',
      license: '工商营业执照',
      net_security: '京公网安备11010502041971号'
    }
  }
}
