export default {
  images: {
    noAuth: {
      title: '您目前还未开通自定义镜像',
      carouselText1: '基于基础镜像创建新的镜像',
      carouselText2: '添加自定义软件',
      carouselText3: '应用镜像',
      carouselText4: '提交时使用镜像',
      apply: '申请企业版使用'
    },
    step: {
      title: '简单4步制作自定义镜像',
      step1: '第1步创建镜像：复制基础镜像以创建新的镜像',
      step2: '第2步制作镜像：首次启动镜像，会对镜像进行初始化，启动成功后，向镜像中添加需要的软件制作镜像',
      step3: '第3步发布镜像：将镜像发布到worker机器上',
      step4: '第4步应用镜像：将镜像添加到提交检测页面的操作系统',
      final: '4步过后可以在提交检测时选择制作的镜像提交'
    },
    make: '镜像制作',
    desc1: '基于沙箱云的基础镜像，通过定制化集成所需软件，衍生出全新镜像，旨在构建一个符合要求的虚拟环境，',
    desc2: '以便在沙箱中运行样本，进而诱发样本展现出更多的潜在恶意行为。',
    add: '新建',
    nodata: '暂无自定义镜像，请先新建镜像'
  }
}
